import React, { useState, useEffect } from "react"
import { navigate, graphql } from "gatsby"
import axios from "axios"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Containers
import { Banner } from "../containers/common"
import Status from "../containers/common/status"

const StatusPage = ({ data }) => {
  const { tributeAutoPublish } = data.status
  const [payment, setPayment] = useState(null)
  const params = new URLSearchParams(
    typeof window !== "undefined" && window.location.search
  )
  const id = params.get("session_id")

  useEffect(() => {
    if (id) {
      const fetchSessionResponse = async () => {
        try {
          const response = await axios.post(
            "/.netlify/functions/session-confirmation",
            {
              id: id,
            }
          )
          if (response.status === 200) {
            setPayment(response.data.session.payment_status)
          }
        } catch (error) {
          fetchSessionResponse()
        }
      }
      fetchSessionResponse()
    } else {
      navigate("/")
    }
  }, [])

  return (
    <Layout>
      <SEO title="Tribute Submission Status | Tribute2Moms" />
      <Banner title="Tribute Submission Status" />
      <Status paymentStatus={payment} autoPublish={tributeAutoPublish} />
    </Layout>
  )
}

export const query = graphql`
  query StatusPageQuery {
    status: contentfulWebsiteDetails {
      tributeAutoPublish
    }
  }
`

export default StatusPage
