import React, { useState, useEffect } from "react"
import axios from "axios"

// Styles
import styles from "./index.module.scss"

const Status = ({ paymentStatus = "Processing...", autoPublish }) => {
  const [publish, setPublish] = useState("Processing...")
  useEffect(() => {
    if (paymentStatus === "paid") {
      const momID = localStorage.getItem("momID")
      const tributeID = localStorage.getItem("tributeID")
      if (momID || tributeID) {
        const publishEntries = async () => {
          if (typeof window !== "undefined") {
            const response = await axios.post(
              "/.netlify/functions/publish-tribute",
              {
                momID,
                tributeID,
              }
            )
            if (response.status === 200) {
              const email =
                response.data.publisedTributeEntry.fields.authorEmail["en-US"]
              setPublish("Published!")
              sendConfirmationEmail(email)
              localStorage.removeItem("tributeID")
              localStorage.removeItem("momID")
            } else {
              setPublish("Failed")
            }
          }
        }
        const sendConfirmationEmail = async email => {
          if (email) {
            const response = await axios.post(
              "/.netlify/functions/confirmation-email",
              {
                email: email,
              }
            )
          }
        }

        if (autoPublish) {
          publishEntries()
        } else {
          setPublish("Tribute will be published after admin approval!")
          localStorage.removeItem("tributeID")
          localStorage.removeItem("momID")
        }
      } else {
        setPublish("Session expired!")
      }
    }
  }, [paymentStatus])

  return (
    <div className="md:h-screen max-w-6xl mx-auto px-4 lg:px-0">
      <div className="pb-12 md:pb-0 mt-16 md:mt-32 mx-auto text-center">
        <h3 className={styles.paymentStatus}>
          Payment Status: <span>{paymentStatus}</span>
        </h3>
        <br />
        <br />
        <h3 className={styles.tributeStatus}>
          Publishing Tribute Status:{" "}
          <span
            className={
              publish === "Processing..."
                ? styles.processing
                : publish === "Failed" || publish === "Session expired!"
                ? styles.failed
                : ""
            }
          >
            {publish}
          </span>
        </h3>

        {publish === "Published" && (
          <p className={`${styles.note} mt-12`}>
            *Tribute will be published over the site in 5-10 mins
          </p>
        )}
        {publish === "Failed" && (
          <p className={`${styles.note} mt-12`}>
            *Something went wrong. Please get in contact with us at
            test@test.com
          </p>
        )}
      </div>
    </div>
  )
}

export default Status
